.webviewer {
  height: 100vh
}

.App {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.App .header {
  width: 100%;
  height: 60px;
  padding: 8px 8px 8px 16px;
  box-sizing: border-box;
  background: #00a5e4;
  font-size: 1.2em;
  line-height: 44px;
  color: white;
}



.greenBadge {
  background-color: rgba(0,201,167,.1);
  color: #00c9a7 !important;
}

.yellowBadge {
  background-color:#FEF0E8;
  color: #F79B67 !important;
}

.redBadge {
  background-color: #F8E1E0;
  color: #d9534f !important;
}

.blueBadge {
  background-color: rgba(55,125,255,.1);
  color: #377dff !important
}

.lightGreyBadge {
  background-color: rgb(247,247,247) !important;
  color:rgb(143,143,143) !important;
}


.otherLightGreyBadge {
  color: #71869d !important;
  background-color: rgba(113,134, 157, .1) !important;
}


.lightBlueBadge {
  /* background-color: rgb(8, 45, 66); */
  background-color: rgb(60,184,255);
  color: #fff !important;
}

.lightCard {
  border: 1px solid rgba(231,234,243,.7) !important;
}

.lightCardNoHover {
  border: 1px solid rgba(231,234,243,.7) !important;
}

.lightCard:hover {
  background-color: #F8F9FA !important;
}


.veryLightGrey {
  background-color: #F9FAFC;
  color:#8c98a4
}


.lightGreyText {
  color: #8e9498;
}

.veryLightGreyText {
  color: #cacfd4;
}

.lightGreyButton:hover {
  background-color: #ececec !important;
}

.bg-dark-blue {
  background-color: #396b97 !important;
}

.pointer {
  cursor: pointer;
}


@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

